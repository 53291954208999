import { ReferenceInput, SelectInput, required } from 'react-admin'
import { useState, useCallback, useEffect } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import MediaGroupsQuickCreateButton from './MediaGroupsQuickCreateButton'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const spySubscription = { values: true }

const MediaGroupsReferenceInput = (props) => {
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])

  return (
    <div className={classes.root}>
      <ReferenceInput
        key={version}
        {...props}
        source="mediaGroup.id"
        reference="media-groups"
        allowEmpty
      >
        <SelectInput optionText="title" />
      </ReferenceInput>

      <MediaGroupsQuickCreateButton onChange={handleChange} />
      {/* {!!values.image && (
        <ImagesQuickPreviewButton id={values.image.id ? values.image.id : values.image} />
      )} */}
    </div>
  )
}

export default MediaGroupsReferenceInput
