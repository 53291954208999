import React from 'react'
import {
  TextInput,
  SelectInput,
  BooleanInput,
  DateTimeInput,
  TabbedForm,
  FormTab,
  required,
  number,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
} from 'react-admin'
import { SURVEY_SHOW_CORRECT_ANSWER_TYPE, SURVEY_TYPE } from '../../constants'
import ImagesReferenceInput from '../common/ImagesReferenceInput'
import QuestionsTab from '../common/QuestionsTab'
import CertificateReferenceInput from './CertificateReferenceInput'

const showCorrectAnswerTypeChoises = SURVEY_SHOW_CORRECT_ANSWER_TYPE.map((item, index) => ({
  id: index,
  name: item,
}))

const typeChoises = SURVEY_TYPE.map((item, index) => ({
  id: index,
  name: item,
}))

const SurveysForm = (props) => {
  return (
    <TabbedForm {...props} initialValues={{ surveyQuestions: [] }}>
      <FormTab label="main">
        <TextInput source="title" validate={[required()]} />
        <BooleanInput source="isEnabled" />
        <DateTimeInput source="startDate" />
        <DateTimeInput source="endDate" />
        <NumberInput source="position" />
        <SelectInput
          source="showCorrectAnswerType"
          validate={[required()]}
          choices={showCorrectAnswerTypeChoises}
        />
        <SelectInput source="type" validate={[required()]} choices={typeChoises} />
        <ImagesReferenceInput />
      </FormTab>
      <FormTab label="accesibility">
        <ReferenceArrayInput
          label="User groups"
          source="userGroups"
          reference="user-groups"
          format={(i) => {
            return i && i.map((m) => (m.id ? m.id : m))
          }}
        >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="locks">
        <BooleanInput source="isLocked" />
        <TextInput source="lockedText" />
        <DateTimeInput
          source="lockedTo"
          options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }}
        />
      </FormTab>
      <FormTab label="survey">
        <QuestionsTab source="surveyQuestions" />
      </FormTab>

      <FormTab label="Survey finish">
        <TextInput source="cost" validate={[required(), number()]} />
        <TextInput source="correctCnt" validate={[required(), number()]} />
        <CertificateReferenceInput />
      </FormTab>
    </TabbedForm>
  )
}

export default SurveysForm
