import React, { useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'

import IconImageEye from '@material-ui/icons/RemoveRedEye'
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Grid from '@material-ui/core/Grid'
import { Button, SimpleShowLayout, TextField, useGetOne } from 'react-admin'
import { servicesHost } from '../../utils/dataprovider'

const useStyles = makeStyles({
  field: {
    '& span': {
      display: 'inline-block',
      maxWidth: '20em',
    },
  },
  drawerPaper: {
    width: '50%',
  },
  iframeContainer: {
    height: '50vh',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
})

const EDetailingMediaSourceQuickPreviewButton = ({ id }) => {
  const [showPanel, setShowPanel] = useState(false)
  const classes = useStyles()
  const { data } = useGetOne('edetailing-media-sources', id)

  const handleClick = () => {
    setShowPanel(true)
  }

  const handleCloseClick = () => {
    setShowPanel(false)
  }

  return (
    <>
      <Button onClick={handleClick} label="ra.action.show">
        <IconImageEye />
      </Button>
      <Drawer
        anchor="right"
        open={showPanel}
        onClose={handleCloseClick}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <Button label="Close" onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </Button>
        </div>
        <SimpleShowLayout
          record={data}
          basePath="/edetailing-media-sources"
          resource="edetailing-media-sources"
        >
          <TextField source="id" />
          <TextField source="title" className={classes.field} />
          <TextField source="uuid" className={classes.field} />
          {data && (
            <div className={classes.iframeContainer}>
              <iframe
                className={classes.iframe}
                src={`${servicesHost.host}/uploads/edetailing/${data.uuid}/index.html`}
              />
            </div>
          )}
        </SimpleShowLayout>
      </Drawer>
    </>
  )
}

export default EDetailingMediaSourceQuickPreviewButton
