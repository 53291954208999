import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  FileInput,
  FileField,
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const PdfMediaSourceQuickCreateButton = ({ onChange }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate('pdf-media-sources')
  const notify = useNotify()
  const form = useForm()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          form.change('pdfSource', data)
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        },
      }
    )
  }

  return (
    <React.Fragment>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create pdf source">
        <DialogTitle>Create pdf source</DialogTitle>

        <FormWithRedirect
          resource="pdf-media-sources"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <React.Fragment>
              <DialogContent>
                <TextInput source="title" validate={required()} fullWidth />
                <FileInput
                  source="file.recordFile"
                  multiple={false}
                  accept="application/pdf"
                  validate={required()}
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </React.Fragment>
          )}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default PdfMediaSourceQuickCreateButton
