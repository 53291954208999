import React, { useState, useEffect, useMemo } from 'react'
//import QuestionHeader from './QuestionHeader';
import { Grid } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

import { Paper, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import AccordionActions from '@material-ui/core/AccordionActions'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Divider from '@material-ui/core/Divider'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import StarIcon from '@material-ui/icons/Star'

import { useInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { SURVEY_QUESTION_TYPE } from '../../constants'

const QUESTION_TYPE_ENUM = {
  RADIO: SURVEY_QUESTION_TYPE.findIndex((i) => i === 'Radio'),
  MULTISELECT: SURVEY_QUESTION_TYPE.findIndex((i) => i === 'Multiple'),
  OPEN: SURVEY_QUESTION_TYPE.findIndex((i) => i === 'Open'),
}

const DraggableItem = ({
  addOption,
  deleteQuestion,
  removeOption,
  handleQuestionValue,
  handleTypeChange,
  handleOptionValue,
  correctAnswerHandler,
  ques,
  i,
}) => {
  const isCorrect = (j) => question.answers[j].isCorrect
  const isMultiple = () => question.type === QUESTION_TYPE_ENUM.MULTISELECT
  const isOpen = () => question.type === QUESTION_TYPE_ENUM.OPEN
  const isRadio = () => question.type === QUESTION_TYPE_ENUM.RADIO

  const [question, setQuestion] = useState(ques)

  const updateExpand = () => {
    setQuestion((prev) => ({ ...prev, open: !prev.open }))
  }

  const updateQuestionValue = (e) => {
    e.preventDefault()
    let newValue = e.target.value

    setQuestion((prev) => {
      let newItem = { ...prev, title: newValue }
      handleQuestionValue(newItem, i)

      return newItem
    })
  }

  const updateOptionValue = (e, optionIndex) => {
    e.preventDefault()

    let newValue = e.target.value

    let newAnswers = [...question.answers]
    newAnswers[optionIndex].title = newValue

    setQuestion((prev) => {
      let newItem = { ...prev, answers: newAnswers }
      handleOptionValue(newItem, i)

      return newItem
    })
  }

  const updateAddOption = (e) => {
    e.preventDefault()
    let newAnswers = [...question.answers]

    newAnswers.push({
      title: 'Option ' + (newAnswers.length + 1),
    })

    setQuestion((prev) => {
      let newItem = { ...prev, answers: newAnswers }
      addOption(newItem, i)

      return newItem
    })
  }

  const updateRemoveOption = (e, optionIndex) => {
    e.preventDefault()
    if (question.answers.length <= 1) {
      return
    }
    let newAnswers = [...question.answers]
    newAnswers.splice(optionIndex, 1)

    setQuestion((prev) => {
      let newItem = { ...prev, answers: newAnswers }
      removeOption(newItem, i)

      return newItem
    })
  }

  const updateHandleTypeChange = (e) => {
    e.preventDefault()
    let value = e.target.value

    setQuestion((prev) => {
      let newAnswers = [...prev.answers]

      if (value === QUESTION_TYPE_ENUM.OPEN) {
        newAnswers.map((value) => {
          value.isCorrect = false
          return value
        })
        newAnswers = newAnswers.slice(0, 1)
      } else {
        newAnswers.map((value) => {
          value.isCorrect = false
          return value
        })
      }

      let newItem = { ...prev, type: value, answers: newAnswers }

      handleTypeChange(newItem, i)

      return newItem
    })
  }

  const updateCorrectAnswerHandler = (e, optionIndex) => {
    e.preventDefault()

    setQuestion((prev) => {
      let newAnswers = [...prev.answers]

      if (prev.type === QUESTION_TYPE_ENUM.OPEN) {
        return
      }

      if (prev.type === QUESTION_TYPE_ENUM.MULTISELECT) {
        newAnswers[optionIndex].isCorrect = !newAnswers[optionIndex].isCorrect
      } else if (prev.type === QUESTION_TYPE_ENUM.RADIO) {
        newAnswers.map((value) => {
          value.isCorrect = false
          return value
        })

        newAnswers[optionIndex].isCorrect = true
      }

      let newItem = { ...prev, answers: newAnswers }

      correctAnswerHandler(newItem, i)

      return newItem
    })
  }

  return (
    <Draggable draggableId={(question.id ? question.id : question.key) + 'id'} index={i}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div>
            <div style={{ marginBottom: '15px' }}>
              <div style={{ width: '100%', marginBottom: '-7px' }}>
                <DragIndicatorIcon
                  style={{ transform: 'rotate(-90deg)', color: '#DAE0E2' }}
                  fontSize="small"
                />
              </div>

              <Accordion
                onChange={() => {
                  updateExpand()
                }}
                expanded={!!question.open}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  elevation={1}
                  style={{ width: '100%' }}
                >
                  {!question.open ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: '3px',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                      }}
                    >
                      {/* <TextField id="standard-basic" label=" " value="Question" InputProps={{ disableUnderline: true }} />  */}

                      <Typography variant="subtitle1" style={{ marginLeft: '0px' }}>
                        {i + 1}. {question.title}
                      </Typography>

                      {question.answers.map((op, j) => (
                        <div key={j}>
                          <div style={{ display: 'flex' }}>
                            <FormControlLabel
                              disabled
                              control={
                                isMultiple() ? (
                                  <Checkbox style={{ marginRight: '3px' }} />
                                ) : isOpen() ? (
                                  <Radio
                                    style={{
                                      marginRight: '3px',
                                    }}
                                  />
                                ) : (
                                  <Radio style={{ marginRight: '3px' }} />
                                )
                              }
                              label={
                                <Typography style={{ color: '#555555' }}>
                                  {question.answers[j].title}
                                </Typography>
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </AccordionSummary>

                <AccordionDetails style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginLeft: '15px',
                      marginTop: '-15px',
                      width: 'calc(100% - 45px)',
                    }}
                  >
                    <Grid container direction="row" alignItems="top" spacing={2}>
                      <Grid item sm={1}>
                        <Typography style={{ marginTop: '20px' }}>{i + 1}.</Typography>
                      </Grid>
                      <Grid item sm={8}>
                        <TextField
                          fullWidth={true}
                          placeholder="Question Text"
                          style={{ marginBottom: '18px' }}
                          rows={2}
                          rowsMax={20}
                          multiline={true}
                          value={question.title}
                          variant="filled"
                          onChange={updateQuestionValue}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <Select
                          fullWidth={true}
                          value={question.type}
                          variant="filled"
                          onChange={updateHandleTypeChange}
                        >
                          {Object.values(SURVEY_QUESTION_TYPE).map((item, idx) => (
                            <MenuItem value={idx} key={idx}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>

                    <div style={{ width: '100%' }}>
                      {question.answers.map((op, j) => (
                        <div key={j}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginLeft: '-12.5px',
                              justifyContent: 'space-between',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                            }}
                          >
                            {isMultiple() ? (
                              <Checkbox style={{ marginRight: '3px' }} disabled />
                            ) : isOpen() ? (
                              <Radio style={{ marginRight: '3px' }} />
                            ) : (
                              <Radio style={{ marginRight: '3px' }} />
                            )}
                            {!isOpen() ? (
                              <>
                                <TextField
                                  fullWidth={true}
                                  placeholder="Option text"
                                  style={{ marginTop: '5px' }}
                                  value={question.answers[j].title}
                                  onChange={(e) => {
                                    updateOptionValue(e, j)
                                  }}
                                />
                                <IconButton
                                  aria-label="correct answer"
                                  onClick={(e) => {
                                    updateCorrectAnswerHandler(e, j)
                                  }}
                                >
                                  {isCorrect(j) ? (
                                    <StarIcon style={{ color: '#2ffa4a' }} />
                                  ) : (
                                    <StarIcon />
                                  )}
                                </IconButton>
                              </>
                            ) : (
                              <TextField
                                fullWidth={true}
                                placeholder="Open question..."
                                style={{ marginTop: '5px' }}
                                disabled
                              />
                            )}

                            {!isOpen() && (
                              <IconButton
                                aria-label="delete"
                                onClick={(e) => {
                                  updateRemoveOption(e, j)
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    {!isOpen() && (
                      <div>
                        <FormControlLabel
                          disabled
                          control={isMultiple() ? <Checkbox /> : <Radio />}
                          label={
                            <Button
                              size="small"
                              onClick={(e) => {
                                updateAddOption(e, i)
                              }}
                              style={{
                                textTransform: 'none',
                                marginLeft: '-5px',
                              }}
                            >
                              Add Option
                            </Button>
                          }
                        />
                      </div>
                    )}

                    <br></br>
                    <br></br>
                  </div>
                </AccordionDetails>

                <Divider />

                <AccordionActions>
                  <Divider orientation="vertical" flexItem />

                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      deleteQuestion(i)
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>

                  <IconButton aria-label="Image">
                    <MoreVertIcon />
                  </IconButton>
                </AccordionActions>
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

const QuestionsUI = ({
  editedValues,
  addOption,
  deleteQuestion,
  removeOption,
  handleQuestionValue,
  handleTypeChange,
  handleOptionValue,
  correctAnswerHandler,
}) => {
  return (
    editedValues &&
    editedValues
      .sort((a, b) => {
        return a.sequence - b.sequence
      })
      .map((ques, i) => (
        <DraggableItem
          key={ques.id ? ques.id : ques.key}
          ques={ques}
          i={i}
          addOption={addOption}
          deleteQuestion={deleteQuestion}
          removeOption={removeOption}
          handleQuestionValue={handleQuestionValue}
          handleTypeChange={handleTypeChange}
          handleOptionValue={handleOptionValue}
          correctAnswerHandler={correctAnswerHandler}
        />
      ))
  )
}

const QuestionsTab = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
  } = useInput(props)

  const form = useForm()

  const [editedValues, setEditedValues] = useState([])

  let FORM_CHANGE_TIMEOUT = null

  const changeFormValue = (data) => {
    clearTimeout(FORM_CHANGE_TIMEOUT)
    FORM_CHANGE_TIMEOUT = setTimeout(() => {
      form.change(
        props.source,
        data ? data.sort((a, b) => a.id - b.id) : editedValues.sort((a, b) => a.id - b.id)
      )
    }, 300)
  }

  // useEffect(() => {
  //   clearTimeout(FORM_CHANGE_TIMEOUT)
  //   FORM_CHANGE_TIMEOUT = setTimeout(() => {
  //     console.log('update')
  //     form.change('examQuestions', [...editedValues.sort((a, b) => a.id - b.id)])
  //   }, 300)
  // }, [editedValues])

  useEffect(() => {
    setEditedValues([...form.getFieldState(props.source).initial])
    // console.log(form.getFieldState('examQuestions').initial)
  }, [])

  function addMoreQuestionField() {
    // expandCloseAll() //I AM GOD

    let tempValue = [...editedValues]

    tempValue.push({
      title: 'Question',
      answers: [{ title: 'Option 1' }],
      open: true,
      sequence: editedValues.length,
      type: QUESTION_TYPE_ENUM.RADIO,
      key: new Date().getTime(),
    })

    setEditedValues((prev) => {
      changeFormValue(tempValue)
      return tempValue
    })
  }

  const correctAnswerHandler = (newItem, i) => {
    editedValues[i] = newItem
    changeFormValue()
  }

  const handleTypeChange = (newItem, i) => {
    editedValues[i] = newItem
    changeFormValue()
  }

  function deleteQuestion(i) {
    setEditedValues((prev) => {
      let qs = [...prev]
      if (prev.length > 1) {
        qs.splice(i, 1)
      }
      changeFormValue(qs)

      return qs
    })
  }

  function handleOptionValue(newItem, i) {
    editedValues[i] = newItem
    changeFormValue()
  }

  function handleQuestionValue(newItem, i) {
    editedValues[i] = newItem
    changeFormValue()
  }

  function addOption(newItem, i) {
    editedValues[i] = newItem
    changeFormValue()
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    setEditedValues((prev) => {
      let newValues = [...prev]
      reorder(newValues, result.source.index, result.destination.index)

      changeFormValue(newValues)

      return newValues
    })
  }

  const reorder = (list, startIndex, endIndex) => {
    let differ = 1

    if (endIndex === 0) {
      differ = 0
    }

    let destSeq = list[endIndex].sequence + differ

    for (let i = endIndex + differ; i <= list.length - 1; i++) {
      list[i].sequence = list[i].sequence + 1
    }

    list[startIndex].sequence = destSeq

    return list
  }

  function removeOption(newItem, i) {
    editedValues[i] = newItem
    changeFormValue()
  }

  return (
    <div style={{ marginTop: '15px', marginBottom: '7px', paddingBottom: '30px' }}>
      <Grid container direction="column" justify="center" alignItems="center">
        {/* {loadingFormData ? <CircularProgress /> : ''} */}

        <Grid item xs={12} sm={10} style={{ width: '100%' }}>
          <Grid style={{ paddingTop: '10px' }}>
            <div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {editedValues.length && (
                        <QuestionsUI
                          editedValues={editedValues}
                          addOption={addOption}
                          deleteQuestion={deleteQuestion}
                          removeOption={removeOption}
                          handleQuestionValue={handleQuestionValue}
                          handleTypeChange={handleTypeChange}
                          handleOptionValue={handleOptionValue}
                          correctAnswerHandler={correctAnswerHandler}
                        />
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div>
                <Button
                  variant="contained"
                  onClick={addMoreQuestionField}
                  endIcon={<AddCircleIcon />}
                  style={{ margin: '5px' }}
                >
                  Add Question{' '}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

QuestionsTab.propTypes = {
  source: PropTypes.string,
}

export default QuestionsTab
