import { TextField, FunctionField, Datagrid, List, BooleanField } from 'react-admin'
import { AGREEMENT_TYPES } from '../../constants'

const AgreementsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <FunctionField label="type" render={(record) => AGREEMENT_TYPES[record.type]} />
      <TextField source="title" />
      <BooleanField source="isRequired" />
      <BooleanField source="isEnabled" />
    </Datagrid>
  </List>
)

export default AgreementsList
