import {
  TextField,
  FunctionField,
  Datagrid,
  List,
  TextInput,
  Filter,
  email,
  downloadCSV,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'

const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" type="email" validate={[email('Invalid email')]} />
  </Filter>
)

const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const {
      comments,
      points,
      isActive,
      houseNo,
      street,
      city,
      postalCode,
      usersAgreements,
      userGroups,
      ...userForExport
    } = user
    userForExport.specialization = user.hasOwnProperty('specialization')
      ? user.specialization.title
      : ''

    for (const userGroup of userGroups) {
      userForExport[`userGroup[${userGroup.id}][${userGroup.title}]`] = true
    }
    for (const agreement of usersAgreements) {
      userForExport[`agreement[${agreement.agreement.id}][${agreement.agreement.title}]`] = true
    }

    return userForExport
  })
  jsonExport(
    usersForExport,
    {
      headers: [
        'id',
        'email',
        'birthDate',
        'firstName',
        'lastName',
        'specialization',
        'score',
        'certAgreement',
        'pwz',
        'voivodeship',
        'phone',
        'isVerified',
        'isMailingUnsub',
        'createdAt',
        'updatedAt',
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'users') // download as 'users.csv` file
    }
  )
}

const UsersList = (props) => (
  <List {...props} filters={<UsersFilter />} exporter={exporter}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="score" />
    </Datagrid>
  </List>
)

export default UsersList
