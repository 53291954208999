import React, { useState, useCallback } from 'react'
import { useInput, required, Loading, useGetList } from 'react-admin'
import IconSelectAll from '@material-ui/icons/SelectAll'
import { Button } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ImagePreviewList from './ImagePreviewList'
import Slide from '@material-ui/core/Slide'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { useForm, useFormState } from 'react-final-form'
import PropTypes from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ImageInput = ({ toChange, label, fieldName, ...props }) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const [showDialog, setShowDialog] = useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const onImageSelect = (image) => {
    setShowDialog(false)
    form.change(fieldName, image)
    toChange()
  }

  const form = useForm()

  return (
    <>
      <Button onClick={handleClick} label={'Select ' + label}>
        <IconSelectAll />
      </Button>
      <Dialog
        fullScreen
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={'Select ' + label}
        TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseClick} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Select {label}</Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle>Select {label}</DialogTitle>
        <DialogContent>
          <ImagePreviewList onImageSelect={onImageSelect} />
        </DialogContent>
      </Dialog>
    </>
  )
}

ImageInput.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
}

ImageInput.defaultProps = {
  fieldName: 'image',
  label: 'image',
}

export default ImageInput
