import React, { useEffect, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { Defs, linearGradientDef } from '@nivo/core'

const Chart = (props) => {
  return (
    <ResponsiveLine
      data={props.stats}
      curve="monotoneX"
      animate={false}
      colors={{ scheme: 'paired' }}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        format: '%b %d',
        //tickValues: "every 2 days",
        // tickRotation: -90,
        legend: 'time scale',
        legendOffset: -12,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'count',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      areaOpacity={0.25}
      useMesh={true}
      enableArea={true}
      fill={[{ match: '*', id: 'gradientA' }]}
      defs={[
        linearGradientDef('gradientA', [
          { offset: 0, color: 'inherit' },
          { offset: 100, color: 'inherit', opacity: 0 },
        ]),
      ]}
      markers={props.emailCampains.concat(props.marketingEntries)}
      legends={[
        {
          anchor: 'top-right',
          direction: 'row',
          justify: false,
          translateX: 45,
          translateY: -25,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 140,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  )
}

export default Chart
