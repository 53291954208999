import React, { useState, useEffect } from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import IconImageEye from '@material-ui/icons/RemoveRedEye'
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Button, SimpleShowLayout, TextField, useGetOne, ImageField } from 'react-admin'

const useStyles = makeStyles({
  field: {
    '& span': {
      display: 'inline-block',
      maxWidth: '20em',
    },
  },
  drawerPaper: {
    width: '50%',
  },
  iframeContainer: {
    height: '50vh',
  },
  img: {
    maxWidth: '100%',
  },
})

const VimeoPreview = ({ url }) => {
  const [vimeoResponse, setVimeoResponse] = useState(null)

  useEffect(() => {
    fetch(`https://vimeo.com/api/oembed.json?url=${url}`)
      .then((r) => r.json())
      .then((data) => setVimeoResponse(data))
      .catch((e) => console.log(e))
  }, [])

  return vimeoResponse && <div dangerouslySetInnerHTML={{ __html: vimeoResponse.html }}></div>
}

const VideoMediaSourceQuickPreviewButton = ({ id }) => {
  const [showPanel, setShowPanel] = useState(false)
  const classes = useStyles()
  const { data } = useGetOne('video-media-sources', id)

  const handleClick = () => {
    setShowPanel(true)
  }

  const handleCloseClick = () => {
    setShowPanel(false)
  }

  return (
    <>
      <Button onClick={handleClick} label="ra.action.show">
        <IconImageEye />
      </Button>
      <Drawer
        anchor="right"
        open={showPanel}
        onClose={handleCloseClick}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <Button label="Close" onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </Button>
        </div>
        <SimpleShowLayout
          record={data}
          basePath="/video-media-sources"
          resource="video-media-sources"
        >
          <TextField source="id" />
          {data && <VimeoPreview url={data.url} />}
        </SimpleShowLayout>
      </Drawer>
    </>
  )
}

export default VideoMediaSourceQuickPreviewButton
