import { TextField, FunctionField, Datagrid, List } from 'react-admin'
import { SETTINGS_TYPE } from '../../constants'

const SettingsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <FunctionField label="type" render={(record) => SETTINGS_TYPE[record.type]} />
      <TextField source="value" />
    </Datagrid>
  </List>
)

export default SettingsList
