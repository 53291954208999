import React from 'react'
import { SimpleForm, TextInput, Create, useNotify, useRedirect } from 'react-admin'

const MediaAuthorsCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = ({ data }) => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('list', props.basePath, data.id, data)
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput source="link" />
      </SimpleForm>
    </Create>
  )
}

export default MediaAuthorsCreate
