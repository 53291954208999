import React from 'react'
import { SimpleForm, TextInput, Edit, SelectInput, required } from 'react-admin'

const MediaAuthorsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput source="link" />
      </SimpleForm>
    </Edit>
  )
}

export default MediaAuthorsEdit
