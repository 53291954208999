import React from 'react'
import { SimpleForm, TextInput, Create, ReferenceInput, ImageInput, ImageField } from 'react-admin'

const ImagesCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ImageInput source="imageFile" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )
}

export default ImagesCreate
