import {
  TextInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  DateTimeInput,
  TabbedForm,
  FormTab,
  required,
  number,
  minValue,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import EDetailingMediaSourceReferenceInput from './EDetailingMediaSourceReferenceInput'
import VideoMediaSourceReferenceInput from './VideoMediaSourceReferenceInput'
import PresentationMediaSourceReferenceInput from './PresentationMediaSourceReferenceInput'
import PdfMediaSourceReferenceInput from './PdfMediaSourceReferenceInput'
import ImagesReferenceInput from '../common/ImagesReferenceInput'
import MediaGroupsReferenceInput from './MediaGroupsReferenceInput'
import { MEDIA_TYPES } from '../../constants'

const typesMap = {
  0: (props) => <EDetailingMediaSourceReferenceInput {...props} />,
  1: (props) => <PresentationMediaSourceReferenceInput {...props} />,
  2: (props) => <VideoMediaSourceReferenceInput {...props} />,
  3: (props) => <VideoMediaSourceReferenceInput {...props} />,
  4: (props) => <PdfMediaSourceReferenceInput {...props} />,
}

const SourceWrapper = (props) => {
  const { values } = useFormState()

  Object.keys(values).forEach((i) => {
    if (i.includes('source')) {
      values[i] = null
    }
  })

  return <div>{typeof values.type !== 'undefined' ? typesMap[values.type](props) : null}</div>
}

const typeChoices = MEDIA_TYPES.map((item, index) => ({
  id: index,
  name: item,
}))

const MediaForm = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="main">
        <SelectInput source="type" validate={[required()]} choices={typeChoices} />
        <NumberInput source="position" />
        <ReferenceInput label="Author" source="author.id" reference="media-authors">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="tags" />
        <ImagesReferenceInput />
      </FormTab>
      <FormTab label="accesibility">
        <BooleanInput source="isEnabled" />
        <BooleanInput source="isPromotedTop" />
        <BooleanInput source="isLocked" />
        <DateTimeInput
          source="publicationDate"
          options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }}
        />
        <TextInput source="lockedText" />
        <BooleanInput source="isLockComments" />
        <DateTimeInput
          source="lockedTo"
          options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }}
        />
        <ReferenceArrayInput
          label="User groups"
          source="userGroups"
          reference="user-groups"
          format={(i) => i && i.map((m) => (m.id ? m.id : m))}
        >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="redirect">
        <BooleanInput source="isRedirect" />
        <NumberInput source="redirectSec" min={0} validate={[number(), minValue(0)]} />
      </FormTab>
      <FormTab label="source">
        <SourceWrapper></SourceWrapper>
      </FormTab>
      <FormTab label="survey">
        <BooleanInput source="isFormBefore" />
        <BooleanInput source="isFormAfter" />
      </FormTab>
    </TabbedForm>
  )
}

export default MediaForm
