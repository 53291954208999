import { useState, useCallback } from 'react'
import { Loading, useGetList } from 'react-admin'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { servicesHost } from '../../utils/dataprovider'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  img: {
    transition: 'all 0.4s',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
    },
  },
}))

const ImagePreviewList = ({ onImageSelect }) => {
  const classes = useStyles()

  const { data, ids, loading, getError } = useGetList(
    'images',
    { page: 1, perPage: 1000 },
    { field: 'createdAt', order: 'DESC' }
  )

  if (loading) {
    return <Loading />
  }
  if (getError) {
    return <p>ERROR</p>
  }

  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4, 1500: 5 }}>
      <Masonry gutter="10px">
        {data &&
          Object.values(data)
            .reverse()
            .map((image, i) => (
              <Box boxShadow={3} className={classes.img} key={image.id}>
                <img
                  src={`${servicesHost.host}/uploads/images/${image.name}`}
                  style={{ width: '100%', display: 'block' }}
                  onClick={() => onImageSelect(image)}
                />
              </Box>
            ))}
      </Masonry>
    </ResponsiveMasonry>
  )
}

export default ImagePreviewList
