import React from 'react'
import { SimpleForm, TextInput, Edit, SelectInput, BooleanInput } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import Quill from 'quill'
import htmlEditButton from 'quill-html-edit-button'
import BlotFormatter from 'quill-blot-formatter'
import ImagesReferenceInput from '../common/ImagesReferenceInput'

Quill.register({
  'modules/htmlEditButton': htmlEditButton,
})
Quill.register('modules/blotFormatter', BlotFormatter)

Quill.register(Quill.import('attributors/style/align'), false)

const PagesEdit = (props) => {
  // const configureQuill = (quill) =>
  //   quill.register({
  //     'modules/toolbar': Toolbar,
  //     'themes/snow': Snow,
  //     'modules/htmlEditButton': htmlEditButton,
  //   })

  const transform = (data) => {
    const newData = {}

    Object.keys(data).forEach((i) => {
      if (typeof data[i] === 'object' && data[i].id) {
        newData[i] = data[i].id
      } else {
        newData[i] = data[i]
      }
    })

    if (newData.id) {
      delete newData.id
    }

    return newData
  }

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="identificator" />
        <BooleanInput label="isVisibleInSlider" source="isVisibleInSlider" />
        <BooleanInput label="isAccessibleFromInternet" source="isAccessibleFromInternet" />
        <ImagesReferenceInput />
        <RichTextInput
          // configureQuill={configureQuill}
          source="content"
          options={{
            modules: {
              htmlEditButton: {},
              blotFormatter: {
                // see config options below
              },
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                ['bold', 'italic', 'underline'],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['blockquote', 'code-block'],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['image', 'video', 'link'],

                ['clean'],
              ],
            },
          }}
        />
      </SimpleForm>
    </Edit>
  )
}

export default PagesEdit
