import { TextField, FunctionField, Datagrid, List, DateField } from 'react-admin'

const MarketingEntriesList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <DateField source="entryDate" />
    </Datagrid>
  </List>
)

export default MarketingEntriesList
