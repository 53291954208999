import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField,
  RichTextField,
  TabbedShowLayout,
  EmailField,
  NumberField,
  Tab,
  DeleteButton,
  RefreshButton,
  TopToolbar,
  ReferenceField,
  ChipField,
  EditButton,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceManyField,
  ArrayField,
} from 'react-admin'
import Timeline from './Timeline'

const UsersShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <DeleteButton basePath={basePath} record={data} />
    <RefreshButton basePath={basePath} record={data} />
  </TopToolbar>
)

const UsersShow = (props) => (
  <Show {...props} actions={<UsersShowActions />}>
    <TabbedShowLayout>
      <Tab label="registration">
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="pwz" />
        <EmailField source="email" />
        <TextField source="postalCode" />
        <ReferenceField
          label="Specialization"
          source="specialization.id"
          reference="specializations"
          link="show"
        >
          <ChipField source="title" />
        </ReferenceField>
        <ArrayField source="userGroups" fieldKey="id">
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ArrayField>
        <BooleanField source="isVerified" />
        <BooleanField source="isMailingUnsub" />
        <DateField label="Created At" source="createdAt" />
      </Tab>
      <Tab label="profile">
        <TextField source="profession" />
        <TextField source="voivodeship" />
        <TextField source="phone" />
        <TextField source="city" />
      </Tab>
      <Tab label="stats">
        <TextField source="score" />
      </Tab>
      <Tab label="agreements">
        <ArrayField source="usersAgreements" fieldKey="id">
          <SingleFieldList>
            <ChipField source="agreement.title" />
          </SingleFieldList>
        </ArrayField>
      </Tab>
      <Tab label="timeline">
        <Timeline />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default UsersShow
