import { TextField, BooleanField, Datagrid, List } from 'react-admin'

const ExamsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="isEnabled" />
      <BooleanField source="isLocked" />
    </Datagrid>
  </List>
)

export default ExamsList
