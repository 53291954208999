import React from 'react'
import { useListContext, List, TextField, DateField, ReferenceField, EditButton } from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import ListSubheader from '@material-ui/core/ListSubheader'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
// import tileData from './tileData'

import { servicesHost } from '../../utils/dataprovider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
  },
  gridList: {
    width: '100%',
    // height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}))

const CustomGrid = () => {
  const classes = useStyles()
  const { ids, data, basePath } = useListContext()

  return (
    <div className={classes.root}>
      <GridList cols={6} col-sm={3} cellHeight={180} className={classes.gridList}>
        {ids.map((id) => (
          <GridListTile key={id}>
            <img src={`${servicesHost.host}/uploads/images/${data[id].name}`} />
            <GridListTileBar title={<TextField record={data[id]} source="originalName" />} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  )
}

const ImagesList = (props) => (
  <List title="All images" {...props}>
    <CustomGrid />
  </List>
)

export default ImagesList
