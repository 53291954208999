import { servicesHost } from './dataprovider'
import refreshTokenHandler from './refreshTokenHandler'

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(`${servicesHost.host}/api/login_check`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || (response.status >= 300 && response.status < 400)) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then((auth) => {
        if (auth.code) {
          throw new Error(auth.message)
        }
        localStorage.setItem('auth', JSON.stringify(auth))
        refreshTokenHandler.subscribeRefreshing()
      })
      .catch((response) => {
        throw new Error(response ? response : 'Network error')
      })
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('auth')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401) {
      const request = new Request(`${servicesHost.host}/api/token/refresh`, {
        method: 'POST',
        body: JSON.stringify({
          refresh_token: JSON.parse(localStorage.getItem('auth')).refresh_token,
        }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })

      return fetch(request)
        .then((response) => {
          if (response.status < 200 || (response.status >= 300 && response.status < 400)) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .then((auth) => {
          if (auth.code) {
            throw new Error(auth.message)
          }
          localStorage.setItem('auth', JSON.stringify(auth))
          refreshTokenHandler.subscribeRefreshing()
        })
        .catch((response) => {
          throw new Error(response ? response : 'Network error')
        })
    }

    // const status = error.status
    if (status === 403) {
      localStorage.removeItem('auth')
      return Promise.reject()
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth')
      ? refreshTokenHandler.waitForTokenRefresh()
      : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
}
