import * as React from 'react'
import { Show, TextField, TabbedShowLayout, Tab, TopToolbar, EditButton } from 'react-admin'
import { EMAIL_CAMPAIGN_STATUS_DRAFT } from '../../constants'

const Actions = ({ basePath, data, resource, ...props }) => {
  return (
    <TopToolbar>
      {data.status === EMAIL_CAMPAIGN_STATUS_DRAFT && (
        <EditButton basePath={basePath} record={data} />
      )}
    </TopToolbar>
  )
}

const EmailCampaignsShow = ({ source, ...props }) => {
  console.log(props)
  return (
    <Show {...props} actions={<Actions />}>
      <TabbedShowLayout>
        <Tab label="main">
          <TextField source="id" />
          <TextField source="title" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default EmailCampaignsShow
