import {
  TextField,
  DateField,
  BooleanField,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
  ChipField,
  FunctionField,
} from 'react-admin'
import { EMAIL_CAMPAIGN_STATUS, EMAIL_CAMPAIGN_STATUS_DRAFT } from '../../constants'

const EmailCampaignsList = (props) => (
  <List {...props}>
    <Datagrid rowClick={'show'}>
      <TextField source="id" />
      <TextField source="title" />
      <FunctionField
        label="status"
        render={(record) =>
          EMAIL_CAMPAIGN_STATUS.hasOwnProperty(record.status)
            ? EMAIL_CAMPAIGN_STATUS[record.status]
            : 'undefined'
        }
      />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
)

export default EmailCampaignsList
