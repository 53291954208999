import React from 'react'
import { SimpleForm, TextInput, Edit, SelectInput, required, BooleanInput } from 'react-admin'

const UsersEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput source="isActive" />
        <TextInput source="plainPassword" />
      </SimpleForm>
    </Edit>
  )
}

export default UsersEdit
