// import ExamsCreate from './ExamsCreate'
import ExamsForm from './ExamsForm'
import ExamsCreateHOC from './ExamsCreateHOC'
import ExamsEditHOC from './ExamsEditHOC'
import ExamsList from './ExamsList'

const ExamsCreate = ExamsCreateHOC(ExamsForm)
const ExamsEdit = ExamsEditHOC(ExamsForm)

export default {
  create: ExamsCreate,
  edit: ExamsEdit,
  list: ExamsList,
}
