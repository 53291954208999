import { TextField, BooleanField, Datagrid, List, ReferenceField, ChipField } from 'react-admin'

const SurveysList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceField
        label="Media group"
        source="mediaGroup.id"
        reference="media-groups"
        link={false}
      >
        <ChipField source="title" />
      </ReferenceField>
      <ReferenceField
        label="Module"
        source="mediaGroup.module.id"
        reference="modules"
        link={'edit'}
      >
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="isEnabled" />
      <BooleanField source="isLocked" />
    </Datagrid>
  </List>
)

export default SurveysList
