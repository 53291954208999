import React, { useState } from 'react'
import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  useDataProvider,
  Confirm,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { servicesHost } from '../../utils/dataprovider'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  field: {
    '& span': {
      display: 'inline-block',
      maxWidth: '20em',
    },
  },
  iframeContainer: {
    height: '50vh',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
})

const EmailPreview = ({ record }) => {
  const classes = useStyles()

  return (
    <div className={classes.iframeContainer}>
      <iframe
        className={classes.iframe}
        src={`${servicesHost.host}/uploads/emailtemplate/${record.uuid}/index.html`}
      />
    </div>
  )
}

const ArchiveButton = ({ basePath, data, resource }) => {
  const dataProvider = useDataProvider()

  const handleClick = async () => {
    const r = await dataProvider.update('email-templates', {
      id: data.id,
      data: { ...data, isArchived: true },
    })
  }

  return data && !data.isArchived ? (
    <>
      <Button color="primary" variant="outlined" onClick={() => handleClick()}>
        Archive
      </Button>
    </>
  ) : null
}

const ShowActions = (props) => {
  return (
    <TopToolbar>
      <ArchiveButton {...props} />
    </TopToolbar>
  )
}

const EmailTemplatesShow = ({ source, ...props }) => {
  const classes = useStyles()

  return (
    <Show {...props} actions={<ShowActions />}>
      <TabbedShowLayout>
        <Tab label="main">
          <TextField source="id" />
          <TextField source="title" className={classes.field} />
          <TextField source="uuid" className={classes.field} />
          <EmailPreview />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default EmailTemplatesShow
