import Typography from '@material-ui/core/Typography'
import EventList from './EventList'

const TimelineLoaded = ({ data, classes }) => {
  const getDayString = (date) =>
    new Date(date).toLocaleDateString('en', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

  return (
    data && (
      <>
        {data.days.map((day) => (
          <div key={day} className={classes.day}>
            <Typography variant="subheading" gutterBottom>
              {getDayString(day)}
            </Typography>
            <EventList events={data.eventsByDay[day]} />
          </div>
        ))}
      </>
    )
  )
}

export default TimelineLoaded
