import EmailCampaignsCreateHOC from './EmailCampaignsCreateHOC'
import EmailCampaignsEditHOC from './EmailCampaignsEditHOC'
import EmailCampaignsForm from './EmailCampaignsForm'
import EmailCampaignsList from './EmailCampaignsList'
import EmailCampaignsShow from './EmailCampaignsShow'

const EmailCampaignsCreate = EmailCampaignsCreateHOC(EmailCampaignsForm)
const EmailCampaignsEdit = EmailCampaignsEditHOC(EmailCampaignsForm)

export default {
  create: EmailCampaignsCreate,
  edit: EmailCampaignsEdit,
  list: EmailCampaignsList,
  show: EmailCampaignsShow,
}
