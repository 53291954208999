import {
  TextField,
  DateField,
  BooleanField,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
  ChipField,
} from 'react-admin'

const CommentsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Media" source="media.id" reference="media">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const CommentsList = (props) => (
  <List {...props} filters={<CommentsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField label="User" source="creator.id" reference="users" link="show">
        <ChipField source="email" />
      </ReferenceField>
      <TextField source="content" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
)

export default CommentsList
