import React from 'react'
import {
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  required,
  useNotify,
  useRedirect,
  BooleanInput,
} from 'react-admin'
import { AGREEMENT_TYPES } from '../../constants'
import Quill from 'quill'
import htmlEditButton from 'quill-html-edit-button'
import BlotFormatter from 'quill-blot-formatter'
import RichTextInput from 'ra-input-rich-text'

Quill.register({
  'modules/htmlEditButton': htmlEditButton,
})
Quill.register('modules/blotFormatter', BlotFormatter)

Quill.register(Quill.import('attributors/style/align'), false)

const typeChoices = AGREEMENT_TYPES.map((item, index) => ({
  id: index,
  name: item,
}))

const AgreementsCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = ({ data }) => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('list', props.basePath, data.id, data)
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <SelectInput source="type" validate={[required()]} choices={typeChoices} />
        <TextInput fullWidth={true} source="title" />
        <RichTextInput
          // configureQuill={configureQuill}
          source="description"
          validate={[required()]}
          options={{
            modules: {
              htmlEditButton: {},
              blotFormatter: {
                // see config options below
              },
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                ['bold', 'italic', 'underline'],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['blockquote', 'code-block'],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['image', 'video', 'link'],

                ['clean'],
              ],
            },
          }}
        />
        <BooleanInput source="isRequired" />
        <BooleanInput source="isEnabled" />
      </SimpleForm>
    </Create>
  )
}

export default AgreementsCreate
