import React, { useState } from 'react'
import clsx from 'clsx'
import { green } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { DEFAULT_GROUP_TITLE } from './constants'

const useStyles = makeStyles((theme) => ({
  fab: {
    // position: 'absolute',
    // bottom: theme.spacing(2),
    // right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}))

const AddGroupButton = ({ addNewItemHandler }) => {
  const classes = useStyles()

  const [showDialog, setShowDialog] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [groupNameError, setGroupNameError] = useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleGroupNameField = (e) => {
    e.preventDefault()
    e.target.value && setGroupNameError(false)
    setGroupName(e.target.value)
  }

  const addNewItem = () => {
    if (!groupName) {
      setGroupNameError('Empty value')
      return
    }

    if (groupName === DEFAULT_GROUP_TITLE) {
      setGroupNameError('Reserved title')
      return
    }

    addNewItemHandler(groupName)
    handleCloseClick()
    setGroupName('')
  }

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      addNewItem()
    }
  }

  return (
    <>
      <Fab
        aria-label={'add'}
        className={clsx(classes.fab, classes.fabGreen)}
        color={'inherit'}
        onClick={handleClick}
      >
        <AddIcon />
      </Fab>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label={'Add group'}>
        <DialogTitle>Add group</DialogTitle>

        <React.Fragment>
          <DialogContent>
            <TextField
              value={groupName}
              error={groupNameError}
              helperText={groupNameError}
              onChange={handleGroupNameField}
              fullWidth
              onKeyDown={onKeyDownHandler}
              autoFocus={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseClick} color={'secondary'}>
              {'cancel'}
            </Button>
            <Button onClick={addNewItem} color={'primary'}>
              {'add'}
            </Button>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </>
  )
}

export default AddGroupButton
