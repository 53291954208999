import * as React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import MainChart from './pages/dashboard/MainChart'
import MediaVisitChart from './pages/dashboard/MediaVisitChart'

import { makeStyles } from '@material-ui/core/styles'
import UsersBySpecialization from './pages/dashboard/UsersBySpecialization'
import UsersByUserGroup from './pages/dashboard/UsersByUserGroup'

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
  },
}))

export default () => {
  const classes = useStyles()

  return (
    <>
      <Card>
        <CardHeader title="Welcome to the administration" />
      </Card>

      <Card className={classes.card}>
        <CardHeader title="Main chart" />
        <CardContent>
          <MainChart />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Media" />
        <CardContent>
          <MediaVisitChart />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Users by specialization" />
        <CardContent>
          <UsersBySpecialization />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Users by user group" />
        <CardContent>
          <UsersByUserGroup />
        </CardContent>
      </Card>
    </>
  )
}
