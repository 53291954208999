import { ReferenceInput, SelectInput, required } from 'react-admin'
import { useState, useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import EdetailingMediaSourceQuickCreateButton from './EDetailingMediaSourceQuickCreateButton'
import EDetailingMediaSourceQuickPreviewButton from './EDetailingMediaSourceQuickPreviewButton'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const spySubscription = { values: true }

const EDetailingMediaSourceReferenceInput = (props) => {
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])

  return (
    <div className={classes.root}>
      <ReferenceInput
        key={version}
        {...props}
        source="eDetailingSource.id"
        reference="edetailing-media-sources"
        allowEmpty
        validate={required()}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>

      <EdetailingMediaSourceQuickCreateButton onChange={handleChange} />
      {!!values.eDetailingSource && (
        <EDetailingMediaSourceQuickPreviewButton
          id={values.eDetailingSource.id ? values.eDetailingSource.id : values.eDetailingSource}
        />
      )}
    </div>
  )
}

export default EDetailingMediaSourceReferenceInput
