import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import AddMediaButton from './AddMediaButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import { DEFAULT_GROUP_TITLE } from './constants'
import AddSurveyButton from './AddSurveyButton'
import ListSubheader from '@material-ui/core/ListSubheader'
import { linkToRecord } from 'react-admin'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  chipMargin: {
    marginRight: theme.spacing(1),
  },
  accordionMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const MediaItem = ({ media, deleteMediaItem }) => {
  const linkToMedia = linkToRecord('/media', media.id, 'edit')
  return (
    <ListItem button>
      {/* <ListItemText primary={media.name} /> */}
      <Link to={linkToMedia} style={{ zIndex: 222 }}>
        {media.name}
      </Link>
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={() => deleteMediaItem()}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const SurveyItem = ({ survey, deleteSurveyItem }) => {
  const linkToSurvey = linkToRecord('/surveys', survey.id, 'edit')
  return (
    <ListItem button>
      {/* <ListItemText primary={survey.title} /> */}
      <Link to={linkToSurvey} style={{ zIndex: 222 }}>
        {survey.title}
      </Link>
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={() => deleteSurveyItem()}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const MediaGroup = ({
  group,
  deleteItem,
  addMediaItem,
  deleteMediaItem,
  deletedMediaItems,
  newAddedMediaItems,
  addSurveyItem,
  deleteSurveyItem,
  deletedSurveyItems,
  newAddedSurveyItems,
}) => {
  const classes = useStyles()

  return (
    <Accordion className={classes.accordionMargin}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container justify={'space-between'}>
          <Grid item>
            <Typography className={classes.heading}>
              <Chip label={group.sequence} className={classes.chipMargin} />
              {group.title}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify={'flex-end'}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => deleteItem()}
                  disabled={DEFAULT_GROUP_TITLE === group.title}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ListSubheader component="div">Media</ListSubheader>
            <List component="nav" aria-label="main mailbox folders">
              {!!group.media.length &&
                group.media.map((item, i) => (
                  <React.Fragment key={item.id}>
                    <MediaItem media={item} deleteMediaItem={() => deleteMediaItem(i)} />
                    <Divider variant="fullWidth" component="li" />
                  </React.Fragment>
                ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <ListSubheader component="div">Surveys</ListSubheader>
            <List component="nav" aria-label="main mailbox folders">
              {!!group.surveys.length &&
                group.surveys.map((item, i) => (
                  <React.Fragment key={item.id}>
                    <SurveyItem survey={item} deleteSurveyItem={() => deleteSurveyItem(i)} />
                    <Divider variant="fullWidth" component="li" />
                  </React.Fragment>
                ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify={'flex-end'} spacing={2}>
              <Grid item>
                <AddMediaButton
                  addMediaItem={addMediaItem}
                  group={group}
                  deletedMediaItems={deletedMediaItems}
                  newAddedMediaItems={newAddedMediaItems}
                />
              </Grid>
              <Grid item>
                <AddSurveyButton
                  addSurveyItem={addSurveyItem}
                  group={group}
                  deletedSurveyItems={deletedSurveyItems}
                  newAddedSurveyItems={newAddedSurveyItems}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default MediaGroup
