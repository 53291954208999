import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Grid } from '@material-ui/core'
import AddGroupButton from './AddGroupButton'
import { useInput } from 'react-admin'
import { useForm } from 'react-final-form'
import MediaGroup from './MediaGroup'
import { DEFAULT_GROUP_TITLE } from './constants'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  chipMargin: {
    marginRight: theme.spacing(1),
  },
  draggableMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function MediaGroupsList(props) {
  const classes = useStyles()

  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
  } = useInput(props)

  const form = useForm()

  const [items, setItems] = useState([])

  const [deletedMediaItems, setDeletedMediaItems] = useState([])
  const [newAddedMediaItems, setNewAddedMediaItems] = useState([])
  const [deletedSurveyItems, setDeletedSurveyItems] = useState([])
  const [newAddedSurveyItems, setNewAddedSurveyItems] = useState([])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const reorder = (list, startIndex, endIndex) => {
      let itemWithStartIndex = null
      let itemWithEndIndex = null
      Object.entries(items).map(([i, item]) => {
        if (item.sequence === startIndex) {
          itemWithStartIndex = item
        }
        if (item.sequence === endIndex) {
          itemWithEndIndex = item
        }
      })
      itemWithStartIndex.sequence = endIndex
      itemWithEndIndex.sequence = startIndex
    }

    setItems((prev) => {
      let newValues = { ...prev }
      reorder(newValues, result.source.index, result.destination.index)

      changeFormValue(newValues)

      return newValues
    })
  }

  const changeFormValue = (data) => {
    form.change(props.source, data ? data : items)
  }

  const deleteItem = (i) => {
    setItems((prev) => {
      let qs = {}

      for (const [key, value] of Object.entries(prev)) {
        if (key === i) {
          continue
        }
        qs[key] = value
      }

      let cnt = 1
      for (const item in qs) {
        qs[item].sequence = cnt
        cnt++
      }

      changeFormValue(qs)

      return qs
    })
  }

  const getNextAvailableId = () => {
    return new Date().getTime()
  }

  const getLastSequenceNum = (items) => {
    let maxNum = 0

    Object.entries(items).map(([i, v]) => {
      if (v.sequence > maxNum) {
        maxNum = v.sequence
      }
    })

    return maxNum
  }

  const addNewItem = (title) => {
    setItems((prev) => {
      let newItem = {
        title,
        sequence: getLastSequenceNum(items) + 1,
        key: new Date().getTime(),
        media: [],
        surveys: [],
      }
      let prevItems = { ...prev }
      prevItems[getNextAvailableId()] = newItem

      changeFormValue(prevItems)

      return prevItems
    })
  }

  const addMediaItem = (groupIndex, media) => {
    if (!items[groupIndex]) {
      return
    }
    setItems((prev) => {
      let prevItems = { ...prev }

      prevItems[groupIndex].media.push(media)

      setDeletedMediaItems([...deletedMediaItems.filter((i) => i.id !== media.id)])
      let prevNewAddedMediaItems = [...newAddedMediaItems]
      prevNewAddedMediaItems.push(media)
      setNewAddedMediaItems([...prevNewAddedMediaItems])

      changeFormValue(prevItems)

      return prevItems
    })
  }

  const deleteMediaItem = (groupIndex, mediaIndex) => {
    if (!items[groupIndex]) {
      return
    }
    setItems((prev) => {
      let prevItems = { ...prev }

      let deleted = prevItems[groupIndex].media.splice(mediaIndex, 1)

      let isNewAdded = newAddedMediaItems.filter((item) => item.id === deleted.id)

      if (isNewAdded.length) {
        setNewAddedMediaItems([
          ...newAddedMediaItems.filter((item) => item.id !== isNewAdded[0].id),
        ])
      } else {
        let prevDeletedItems = [...deletedMediaItems]
        prevDeletedItems.push(deleted[0])
        setDeletedMediaItems(prevDeletedItems)
      }

      changeFormValue(prevItems)

      return prevItems
    })
  }

  const addSurveyItem = (groupIndex, survey) => {
    if (!items[groupIndex]) {
      return
    }
    setItems((prev) => {
      let prevItems = { ...prev }

      prevItems[groupIndex].surveys.push(survey)

      setDeletedSurveyItems([...deletedSurveyItems.filter((i) => i.id !== survey.id)])
      let prevNewAddedSurveyItems = [...newAddedSurveyItems]
      prevNewAddedSurveyItems.push(survey)
      setNewAddedSurveyItems([...prevNewAddedSurveyItems])

      changeFormValue(prevItems)

      return prevItems
    })
  }

  const deleteSurveyItem = (groupIndex, surveyIndex) => {
    if (!items[groupIndex]) {
      return
    }
    setItems((prev) => {
      let prevItems = { ...prev }

      let deleted = prevItems[groupIndex].surveys.splice(surveyIndex, 1)

      let isNewAdded = newAddedSurveyItems.filter((item) => item.id === deleted.id)

      if (isNewAdded.length) {
        setNewAddedSurveyItems([
          ...newAddedSurveyItems.filter((item) => item.id !== isNewAdded[0].id),
        ])
      } else {
        let prevDeletedItems = [...deletedSurveyItems]
        prevDeletedItems.push(deleted[0])
        setDeletedSurveyItems(prevDeletedItems)
      }

      changeFormValue(prevItems)

      return prevItems
    })
  }

  useEffect(() => {
    if (form.getFieldState(props.source).initial) {
      setItems({ ...form.getFieldState(props.source).initial })
    } else {
      setTimeout(() => {
        setItems((prev) => {
          const newIndex = new Date().getTime()
          let newValue = {
            newIndex: {
              title: DEFAULT_GROUP_TITLE,
              sequence: 1,
              key: new Date().getTime(),
              media: [],
              surveys: [],
            },
          }
          changeFormValue(newValue)
          return newValue
        })
      }, 100)
    }
  }, [])

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="top" spacing={2}>
        <Grid item sm={12}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {Object.entries(items)
                    .sort(([, a], [, b]) => {
                      return a.sequence - b.sequence
                    })
                    .map(([i, group]) => {
                      return (
                        <Draggable key={i} draggableId={i + 'id'} index={group.sequence}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.draggableMargin}
                            >
                              <MediaGroup
                                key={i}
                                group={group}
                                deleteItem={() => deleteItem(i)}
                                addMediaItem={(media) => addMediaItem(i, media)}
                                deleteMediaItem={(mediaIndex) => deleteMediaItem(i, mediaIndex)}
                                deletedMediaItems={deletedMediaItems}
                                newAddedMediaItems={newAddedMediaItems}
                                addSurveyItem={(survey) => addSurveyItem(i, survey)}
                                deleteSurveyItem={(surveyIndex) => deleteSurveyItem(i, surveyIndex)}
                                deletedSurveyItems={deletedSurveyItems}
                                newAddedSurveyItems={newAddedSurveyItems}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
        <Grid container sm={12} justify={'flex-end'}>
          <AddGroupButton addNewItemHandler={addNewItem} />
        </Grid>
      </Grid>
    </div>
  )
}
