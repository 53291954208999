// import CommentsCreate from './CommentsCreate'
// import CommentsEdit from './CommentsEdit'
import CommentsList from './CommentsList'
import CommentsShow from './CommentsShow'

export default {
  // create: CommentsCreate,
  // edit: CommentsEdit,
  list: CommentsList,
  show: CommentsShow,
}
