import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField,
  RichTextField,
  TabbedShowLayout,
  EmailField,
  NumberField,
  Tab,
  DeleteButton,
  RefreshButton,
  TopToolbar,
  ReferenceField,
  ChipField,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'

const SurveyFillEntriesShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <RefreshButton basePath={basePath} record={data} />
  </TopToolbar>
)

const SurveyFillEntriesShow = (props) => (
  <Show {...props} actions={<SurveyFillEntriesShowActions />}>
    <TabbedShowLayout>
      <Tab label="main">
        <TextField source="id" />
        <BooleanField source="isFinished" />
        <TextField source="correctCnt" />
        <TextField source="incorrectCnt" />
        <ReferenceField label="User" source="user.id" reference="users" link="show">
          <ChipField source="email" />
        </ReferenceField>
        <ReferenceField label="Survey" source="survey.id" reference="surveys" link="edit">
          <ChipField source="title" />
        </ReferenceField>
        <DateField label="Created At" source="createdAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default SurveyFillEntriesShow
