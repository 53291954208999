import {
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  TextField,
  BooleanField,
  Datagrid,
  List,
  ReferenceField,
  ChipField,
  FunctionField,
  BooleanInput,
  NumberField,
} from 'react-admin'
import { MEDIA_TYPES } from '../../constants'

const MediaFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Media group" source="mediaGroup.id" reference="media-groups">
      <SelectInput optionText="title" />
    </ReferenceInput>
    <BooleanInput source="isPromotedTop" />
  </Filter>
)

const MediaList = (props) => (
  <List {...props} filters={<MediaFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="isEnabled" />
      <ReferenceField
        label="Media group"
        source="mediaGroup.id"
        reference="media-groups"
        link={false}
      >
        <ChipField source="title" />
      </ReferenceField>
      <ReferenceField
        label="Module"
        source="mediaGroup.module.id"
        reference="modules"
        link={'edit'}
      >
        <ChipField source="name" />
      </ReferenceField>
      <FunctionField
        label="type"
        render={(record) => (MEDIA_TYPES[record.type] ? MEDIA_TYPES[record.type] : 'undefined')}
      />
      <BooleanField source="isLocked" />
      <BooleanField source="isPromotedTop" />
      <NumberField source="position" />
    </Datagrid>
  </List>
)

export default MediaList
