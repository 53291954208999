import EmailTemplatesCreateHOC from './EmailTemplatesCreateHOC'
import EmailTemplatesEditHOC from './EmailTemplatesEditHOC'
import EmailTemplatesForm from './EmailTemplatesForm'
import EmailTemplatesList from './EmailTemplatesList'
import EmailTemplatesShow from './EmailTemplatesShow'

const EmailTemplatesCreate = EmailTemplatesCreateHOC(EmailTemplatesForm)
// const EmailTemplatesEdit = EmailTemplatesEditHOC(EmailTemplatesForm)

export default {
  create: EmailTemplatesCreate,
  // edit: EmailTemplatesEdit,
  list: EmailTemplatesList,
  show: EmailTemplatesShow,
}
