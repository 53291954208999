import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import PropTypes from 'prop-types'

const ImagesQuickCreateButton = ({ onChange, reference, label, fieldName }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate(reference)
  const notify = useNotify()
  const form = useForm()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          form.change(fieldName, data)
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        },
      }
    )
  }

  return (
    <React.Fragment>
      <Button onClick={handleClick} label="Add">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label={'Add ' + label}>
        <DialogTitle>Add {label}</DialogTitle>

        <FormWithRedirect
          resource={reference}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <React.Fragment>
              <DialogContent>
                <ImageInput source="imageFile" accept="image/*">
                  <ImageField source="src" title="title" />
                </ImageInput>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </React.Fragment>
          )}
        />
      </Dialog>
    </React.Fragment>
  )
}

ImagesQuickCreateButton.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
}

ImagesQuickCreateButton.defaultProps = {
  source: 'image.id',
  reference: 'images',
  fieldName: 'image',
  label: 'image',
}

export default ImagesQuickCreateButton
