import React, { useEffect, useState } from 'react'
import { useDataProvider, Loading } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Chart from './Chart'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Slide from '@material-ui/core/Slide'
import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  controlsMargin: {
    marginLeft: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const MainChart = (props) => {
  const classes = useStyles()

  const initialDateTo = new Date()
  const initialDateFrom = new Date()
  initialDateFrom.setMonth(initialDateTo.getMonth() - 1)

  const [dateTo, setDateTo] = useState(initialDateTo)
  const [dateFrom, setDateFrom] = useState(initialDateFrom)
  const [selectedMedia, setSelectedMedia] = useState()
  const [selectedSpecialization, setSelectedSpecialization] = useState()

  const [selectedUserGroups, setSelectedUserGroups] = useState([])
  const [isUserGroupsEnabled, setIsUserGroupsEnabled] = useState(false)
  const [userGroups, setUserGroups] = useState([])

  const [media, setMedia] = useState([])
  const [specializations, setSpecializations] = useState([])

  const [showDialog, setShowDialog] = useState(false)

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(true)

  const [stats, setStats] = useState({ data: [] })

  const [visitActions, setVisitActions] = useState(true)
  const [commentAddActions, setCommentAddActions] = useState(true)
  const [emailLinkRegistrationActions, setEmailLinkRegistrationActions] = useState(true)
  const [emailLinkVisitActions, setEmailLinkVisitActions] = useState(true)
  const [emailOpenActions, setEmailOpenActions] = useState(true)
  const [loginActions, setLoginActions] = useState(true)
  const [mediaLikeActions, setMediaLikeActions] = useState(true)
  const [registerActions, setRegisterActions] = useState(true)
  const [userVerificationActions, setUserVerificationActions] = useState(true)
  const [surveyFinishActions, setSurveyFinishActions] = useState(true)
  const [emailCampainsVisibility, setEmailCampainsVisibility] = useState(true)
  const [marketingEntriesVisibility, setMarketingEntriesVisibility] = useState(true)

  const [emailCampains, setEmailCampains] = useState([])
  const [marketingEntries, setMarketingEntries] = useState([])

  const parseByDate = (data, id, color) => {
    let result = {}

    const selectedUserGroupsIds = selectedUserGroups.map((item) => item.id)

    const isUserFitsForSelectedUserGroups = (user) => {
      for (let i = 0; i <= user.userGroups.length - 1; i++) {
        if (selectedUserGroupsIds.includes(user.userGroups[i].id)) {
          return true
        }
      }

      return false
    }

    for (const item of data) {
      if (
        item.hasOwnProperty('user') &&
        item.user.hasOwnProperty('specialization') &&
        selectedSpecialization &&
        item.user.specialization.id !== parseInt(selectedSpecialization)
      ) {
        continue
      }
      if (
        item.hasOwnProperty('user') &&
        isUserGroupsEnabled &&
        !isUserFitsForSelectedUserGroups(item.user)
      ) {
        continue
      }

      let key = item.createdAt.slice(0, 10)
      if (result.hasOwnProperty(key)) {
        result[key].push(item)
      } else {
        result[key] = []
        result[key].push(item)
      }
    }

    let resultFormatted = {}
    resultFormatted.id = id
    resultFormatted.color = color
    resultFormatted.data = []

    for (const [key, item] of Object.entries(result)) {
      resultFormatted.data.push({ x: key, y: item.length })
    }

    return resultFormatted.data.length ? resultFormatted : false
  }

  const parseEmailCampaigns = (data) => {
    let result = []

    for (const item of data) {
      let formattedItem = {}
      formattedItem.axis = 'x'
      formattedItem.value = new Date(item.createdAt.slice(0, 10))
      formattedItem.lineStyle = { stroke: '#b0413e', strokeWidth: 2 }
      formattedItem.legend = 'Mailing'
      formattedItem.textStyle = {
        transform: 'translateX(11px) rotate(90deg)',
      }

      result.push(formattedItem)
    }

    return result
  }

  const parseMarketingEntries = (data) => {
    let result = []

    for (const item of data) {
      let formattedItem = {}
      formattedItem.axis = 'x'
      formattedItem.value = new Date(item.entryDate.slice(0, 10))
      formattedItem.lineStyle = { stroke: '#0fd443', strokeWidth: 2 }
      formattedItem.legend = item.title
      formattedItem.textStyle = {
        fill: 'red',
        transform: 'translateX(11px) rotate(90deg)',
      }

      result.push(formattedItem)
    }

    return result
  }

  const fetchData = async () => {
    let data = []

    setLoading(true)

    if (visitActions) {
      let filter = {
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      }
      if (selectedMedia) {
        filter.media = selectedMedia
      }
      const { data: data1 } = await dataProvider.getList('media-visit-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: filter,
        sort: { field: 'id', order: 'ASC' },
      })

      if (!data) {
        return
      }

      let parsed = parseByDate(
        data1,
        `Wyświetlenia materiałów ${data1.length}`,
        'hsl(91, 70%, 50%)'
      )
      parsed && data.push(parsed)
    }

    if (commentAddActions) {
      let filter = {
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      }
      if (selectedMedia) {
        filter.media = selectedMedia
      }
      const { data: data2 } = await dataProvider.getList('comment-add-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: filter,
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data2, `Komentarze ${data2.length}`, 'hsl(125, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (emailLinkRegistrationActions) {
      const { data: data3 } = await dataProvider.getList('email-link-registration-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: {
          _dateRange: {
            createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
          },
        },
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data3, `Rejestracje z mailingu ${data3.length}`, 'hsl(45, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (emailLinkVisitActions) {
      const { data: data4 } = await dataProvider.getList('email-link-visit-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: {
          _dateRange: {
            createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
          },
        },
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data4, `Kliknięcia mailing ${data4.length}`, 'hsl(72, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (emailOpenActions) {
      const { data: data5 } = await dataProvider.getList('email-open-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: {
          _dateRange: {
            createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
          },
        },
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data5, `Otwarcia maila ${data5.length}`, 'hsl(34, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (loginActions) {
      const { data: data6 } = await dataProvider.getList('login-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: {
          _dateRange: {
            createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
          },
        },
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data6, `Zalogowania ${data6.length}`, 'hsl(34, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (mediaLikeActions) {
      let filter = {
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      }
      if (selectedMedia) {
        filter.media = selectedMedia
      }
      const { data: data7 } = await dataProvider.getList('media-like-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: filter,
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data7, `Polubienia materiału ${data7.length}`, 'hsl(34, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (registerActions) {
      const { data: data8 } = await dataProvider.getList('register-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: {
          _dateRange: {
            createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
          },
        },
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data8, `Rejestracje ${data8.length}`, 'hsl(34, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (userVerificationActions) {
      const { data: data9 } = await dataProvider.getList('user-verification-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: {
          _dateRange: {
            createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
          },
        },
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data9, `Weryfikacje kont ${data9.length}`, 'hsl(34, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (surveyFinishActions) {
      const { data: data10 } = await dataProvider.getList('survey-finish-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: {
          _dateRange: {
            createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
          },
        },
        sort: { field: 'id', order: 'ASC' },
      })

      let parsed = parseByDate(data10, `Zakończone testy ${data10.length}`, 'hsl(34, 70%, 50%)')
      parsed && data.push(parsed)
    }

    if (emailCampainsVisibility) {
      let ecFilter = {
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
        isTest: 0,
      }
      if (selectedMedia) {
        ecFilter.media = selectedMedia
      }
      const { data: ecData } = await dataProvider.getList('email-campaigns', {
        pagination: { page: 1, perPage: 999999 },
        filter: ecFilter,
        sort: { field: 'id', order: 'ASC' },
      })

      setEmailCampains(parseEmailCampaigns(ecData))
    } else {
      setEmailCampains([])
    }

    if (marketingEntriesVisibility) {
      let meFilter = {
        _dateRange: {
          entryDate: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      }
      if (selectedMedia) {
        meFilter.media = selectedMedia
      }
      const { data: meData } = await dataProvider.getList('marketing-entries', {
        pagination: { page: 1, perPage: 999999 },
        filter: meFilter,
        sort: { field: 'id', order: 'ASC' },
      })

      setMarketingEntries(parseMarketingEntries(meData))
    } else {
      setMarketingEntries([])
    }

    const { data: dMedia } = await dataProvider.getList('media', {
      pagination: { page: 1, perPage: 999999 },
      filter: {},
      sort: { field: 'id', order: 'ASC' },
    })

    setMedia(dMedia)

    const { data: dSpecializations } = await dataProvider.getList('specializations', {
      pagination: { page: 1, perPage: 999999 },
      filter: {},
      sort: { field: 'id', order: 'ASC' },
    })

    setSpecializations(dSpecializations)

    if (!data.length) {
      data.push({ data: [] })
    }

    setStats(data)
    setLoading(false)
  }

  useEffect(async () => {
    await fetchData()
    const { data: uGroups } = await dataProvider.getList('user-groups', {
      pagination: { page: 1, perPage: 999999 },
      filter: {},
      sort: { field: 'id', order: 'ASC' },
    })

    setUserGroups(uGroups)
  }, [])

  return (
    <div>
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="start">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline1"
              label="Date from"
              value={dateFrom}
              onChange={setDateFrom}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline2"
              label="Date to"
              value={dateTo}
              onChange={setDateTo}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={classes.controlsMargin}
            />
            <div className={classes.controlsMargin}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  fetchData()
                }}
              >
                Update
              </Button>
            </div>
            <div className={classes.controlsMargin}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowDialog(true)
                }}
              >
                fullScreen
              </Button>
            </div>
          </Grid>

          <Grid container justify="start">
            <FormControlLabel
              control={
                <Switch
                  checked={visitActions}
                  onChange={(e) => setVisitActions(!visitActions)}
                  color="primary"
                />
              }
              label="Wyświetlenia materiałów"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={commentAddActions}
                  onChange={(e) => setCommentAddActions(!commentAddActions)}
                  color="primary"
                />
              }
              label="Komentarze"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={emailLinkRegistrationActions}
                  onChange={(e) => setEmailLinkRegistrationActions(!emailLinkRegistrationActions)}
                  color="primary"
                />
              }
              label="Rejestracje z mailingu"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={emailLinkVisitActions}
                  onChange={(e) => setEmailLinkVisitActions(!emailLinkVisitActions)}
                  color="primary"
                />
              }
              label="Kliknięcia mailing"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={emailOpenActions}
                  onChange={(e) => setEmailOpenActions(!emailOpenActions)}
                  color="primary"
                />
              }
              label="Otwarcia mailing"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={loginActions}
                  onChange={(e) => setLoginActions(!loginActions)}
                  color="primary"
                />
              }
              label="Zalogowania"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={mediaLikeActions}
                  onChange={(e) => setMediaLikeActions(!mediaLikeActions)}
                  color="primary"
                />
              }
              label="Polubienia materiału"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={registerActions}
                  onChange={(e) => setRegisterActions(!registerActions)}
                  color="primary"
                />
              }
              label="Rejestracje"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={userVerificationActions}
                  onChange={(e) => setUserVerificationActions(!userVerificationActions)}
                  color="primary"
                />
              }
              label="Weryfikacje kont"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={surveyFinishActions}
                  onChange={(e) => setSurveyFinishActions(!surveyFinishActions)}
                  color="primary"
                />
              }
              label="Zakończone testy"
            />
          </Grid>
          <Grid container>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={emailCampainsVisibility}
                    onChange={(e) => setEmailCampainsVisibility(!emailCampainsVisibility)}
                    color="secondary"
                  />
                }
                label="Mailing"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={marketingEntriesVisibility}
                    onChange={(e) => setMarketingEntriesVisibility(!marketingEntriesVisibility)}
                    color="secondary"
                  />
                }
                label="Zdarzenia marketingowe"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isUserGroupsEnabled}
                    onChange={() => setIsUserGroupsEnabled(!isUserGroupsEnabled)}
                    color="primary"
                  />
                }
                label="Is user groups filter enabled"
              />
            </Grid>
            <Grid item>
              <Select
                multiple
                disabled={!isUserGroupsEnabled}
                value={selectedUserGroups}
                onChange={(e) => {
                  setSelectedUserGroups(e.target.value)
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selectedUserGroups.map((value) => (
                      <Chip key={value.id} label={value.title} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {userGroups.map((group) => (
                  <MenuItem key={group.id} value={group}>
                    {group.title}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container justify="start">
            <div>
              <InputLabel htmlFor="media-native-simple">Media</InputLabel>
              <Select
                native
                value={selectedMedia}
                onChange={(e) => {
                  e.preventDefault()
                  setSelectedMedia(e.target.value)
                }}
                inputProps={{ id: 'media-native-simple' }}
              >
                <option aria-label="None" value="" />
                {media.map((i) => {
                  return (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  )
                })}
              </Select>
            </div>
            <div className={classes.controlsMargin}>
              <InputLabel htmlFor="specialization-native-simple">Specialization</InputLabel>
              <Select
                native
                value={selectedSpecialization}
                onChange={(e) => {
                  e.preventDefault()
                  setSelectedSpecialization(e.target.value)
                }}
                inputProps={{ id: 'specialization-native-simple' }}
              >
                <option aria-label="None" value="" />
                {specializations.map((i) => {
                  return (
                    <option key={i.id} value={i.id}>
                      {i.title}
                    </option>
                  )
                })}
              </Select>
            </div>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
      <div style={{ height: '600px' }}>
        {loading ? (
          <Loading />
        ) : (
          <Chart marketingEntries={marketingEntries} emailCampains={emailCampains} stats={stats} />
        )}
      </div>
      <Dialog
        fullScreen
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={'Chart'}
        TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseClick} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Chart</Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle>Chart</DialogTitle>
        <DialogContent>
          <Chart marketingEntries={marketingEntries} emailCampains={emailCampains} stats={stats} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainChart
