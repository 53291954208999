import React, { useState, useCallback } from 'react'
import { Button, useCreate, useNotify } from 'react-admin'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useForm, useFormState } from 'react-final-form'
import { v4 as uuidv4 } from 'uuid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { green } from '@material-ui/core/colors'

const spySubscription = { values: true }

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonError: {
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const VideoMediaSourceFetchThumbnail = ({ videoSource }) => {
  const { values } = useFormState({ subscription: spySubscription })
  const form = useForm()
  const [create, { loading }] = useCreate('images')
  const notify = useNotify()

  const classes = useStyles()

  const [thumbLoading, setThumbLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const buttonClassname = clsx(
    {
      [classes.buttonSuccess]: success,
    },
    { [classes.buttonError]: error }
  )

  const handleClick = async () => {
    setThumbLoading(true)
    setError(false)
    setSuccess(false)

    try {
      const data = await fetch(
        `https://vimeo.com/api/oembed.json?url=${values.videoSource.url}`
      ).then((r) => r.json())

      if (!data.thumbnail_url) {
        throw new Error('No thumbnail')
      }

      const blob = await fetch(data.thumbnail_url).then((r) => r.blob())
      const file = new File([blob], uuidv4() + '.jpg', { type: 'image/jpg' })
      file.path = file.name

      const obj = {
        imageFile: { rawFile: file },
        src: URL.createObjectURL(blob),
        title: file.name,
      }

      create(
        { payload: { data: obj } },
        {
          onSuccess: ({ data }) => {
            form.change('image', data)
          },
          onFailure: ({ error }) => {
            notify(error.message, 'error')
          },
        }
      )

      setSuccess(true)
    } catch (e) {
      setError(true)
    }
    setThumbLoading(false)
  }

  return (
    <>
      <div className={classes.wrapper}>
        <Button
          onClick={handleClick}
          label="Fetch Thumbnail"
          variant="contained"
          color="primary"
          disabled={thumbLoading}
          className={buttonClassname}
        >
          <GetAppIcon />
        </Button>
        {thumbLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </>
  )
}

export default VideoMediaSourceFetchThumbnail
