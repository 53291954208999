import { TextField, BooleanField, Datagrid, List } from 'react-admin'

const ModulesList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="isEnabled" />
      <BooleanField source="isLocked" />
      <TextField source="position" />
    </Datagrid>
  </List>
)

export default ModulesList
