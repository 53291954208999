import React from 'react'
import {
  SimpleForm,
  TextInput,
  Create,
  required,
  useNotify,
  useRedirect,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

const MarketingEntriesCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = ({ data }) => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('list', props.basePath, data.id, data)
  }

  const transform = (data) => {
    const newData = {}

    Object.keys(data).forEach((i) => {
      if (Array.isArray(data[i])) {
        newData[i] = data[i].map((item) => (item.id ? item.id : item))
      } else if (typeof data[i] === 'object' && data[i].id) {
        newData[i] = data[i].id
      } else {
        newData[i] = data[i]
      }
    })

    if (newData.id) {
      delete newData.id
    }

    return newData
  }

  return (
    <Create {...props} onSuccess={onSuccess} transform={transform}>
      <SimpleForm>
        <TextInput fullWidth={true} source="title" validate={[required()]} />
        <ReferenceInput
          label="Media"
          source="media.id"
          reference="media"
          allowEmpty
          filterToQuery={(searchText) => ({ _q: { name: searchText } })}
        >
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <DateInput source="entryDate" validate={[required()]} />
      </SimpleForm>
    </Create>
  )
}

export default MarketingEntriesCreate
